<template>
    <div
        class="c-home"
        :class="store.state.mobileLayout ? '-mobile' : '-desktop'"
    >
        <div class="c-home_bg">
            <video
                :src="video"
                autoplay
                loop
                playsinline
                muted
                controls="muted"
                aria-hidden="true"
                focusable="false"
            ></video>
        </div>
        <div
            v-if="!store.state.mobileLayout"
            class="c-home_hover"
            @mousemove="mousemove"
        >
            <router-link
                v-for="product in store.state.products"
                :key="`products-hover-${product.id}`"
                :to="`/product/${product.id}`"
                :aria-label="`Discover ${product.name}`"
                @mouseenter="hoverProduct(product.id)"
            />
        </div>

        <div class="c-home_inner">
            <anim-text
                ref="title"
                tag="h1"
                :text="title"
                class="c-home_title | o-text -medium"
            />
            <ul class="c-home_list">
                <li
                    v-for="product in store.state.products"
                    :key="`products-item-${product.id}`"
                    ref="product"
                    class="c-home_item"
                >
                    <anim-text
                        tag="h2"
                        :text="`${product.name}→`"
                        type="chars"
                        :visible="activeProductId === product.id"
                        class="c-home_name | c-heading -h2"
                    />
                    <anim-text
                        :text="product.desc"
                        :separatorIcon="true"
                        :visible="activeProductId === product.id"
                        class="c-home_desc | o-text"
                    />
                </li>
            </ul>
            <div
                v-if="store.state.mobileLayout"
                class="c-home_counter"
            >
                <span class="c-home_indexes">
                    <anim-text
                        v-for="i in store.totalProducts"
                        :key="`home-counter-${i}`"
                        tag="span"
                        type="chars"
                        :text="`0${i}`"
                        :visible="i - 1 === sliderIndex"
                        class="c-home_index | c-heading -h2"
                    />
                </span>
                <anim-text
                    tag="span"
                    :text="`/0${store.totalProducts}`"
                    :reveal="true"
                    class="c-home_total | c-heading -h2"
                />
            </div>
            <span
                v-if="store.state.mobileLayout"
                class="c-home_proxy"
                @touchstart="touchstart"
                @touchend="touchend"
            ></span>
            <nav
                v-if="store.state.mobileLayout"
                class="c-home_nav"
            >
                <app-button
                    v-if="sliderIndex >= 0"
                    tag="router-link"
                    :to="`/product/${store.state.products[sliderIndex].id}`"
                    label="View"
                    display="primary"
                    arrow="right"
                    class="c-home_button -product"
                />
                <button
                    class="c-home_button -prev"
                    @click="previous()"
                    v-hover-sound
                >
                </button>
                <button
                    class="c-home_button -next"
                    @click="next()"
                    v-hover-sound
                >
                </button>
            </nav>
        </div>
    </div>
</template>

<script>

import AnimText     from '@/templates/objects/AnimText'
import AppButton    from '@/templates/components/AppButton'
import { assets }   from '@/data'

export default {
    name: 'Home',
    inject: ['store'],
    components: {
        AnimText,
        AppButton,
    },
    data: () => ({
        // title: 'Rise Higher with the Doctor™',
        title: 'Rise with us',
        activeProductId: -1,
        sliderIndex: -1,
        mouseX: window.innerWidth/2,
        touchX: null,
        timer: false,
    }),
    mounted() {
        this.$refs.title.show()
    },
    methods: {
        hoverProduct(productID) {
            if(this.store.state.isReady) {
                this.activeProductId = productID
                this.store.setProductHover(productID)
            }
        },

        mousemove(e) {
            this.mouseX = e.clientX
        },

        next() {
            if(this.store.state.isReady && this.store.state.mobileLayout && !this.store.state.isSwiping) {
                this.sliderIndex = (this.sliderIndex + 1) % this.store.totalProducts
            }
        },

        previous() {
            if(this.store.state.isReady && this.store.state.mobileLayout && !this.store.state.isSwiping) {
                // Keep modulo positive
                this.sliderIndex = (((this.sliderIndex - 1) % this.store.totalProducts) + this.store.totalProducts) % this.store.totalProducts
            }
        },

        touchstart(e) {
            this.touchX = e.changedTouches[0].screenX
        },

        touchend(e) {
            if(!this.store.state.mobileLayout || this.store.state.isSwiping) {
                return
            }

            const touchEndX = e.changedTouches[0].screenX

            // If tap, go to product page
            if(this.touchX === touchEndX) {
                this.$router.push({
                    name: 'Product',
                    params: {
                        id: this.store.getProductIdByIndex(this.sliderIndex)
                    }
                })
            }
            // Else swipe
            else if(this.touchX < touchEndX) {
                this.previous()
            } else {
                this.next()
            }
        }
    },
    computed: {
        video() {
            return assets.video
        }
    },
    watch: {
        'store.state.isReady': function(ready) {
            if(ready) {

                if(this.store.state.mobileLayout) {
                    this.sliderIndex = 0
                } else {
                    const productIndex = Math.floor(this.mouseX/window.innerWidth * this.store.totalProducts)
                    const productId = this.store.getProductIdByIndex(productIndex)
                    this.hoverProduct(productId);
                }

            }
        },
        sliderIndex(newIndex) {
            const productId = this.store.getProductIdByIndex(newIndex)
            this.hoverProduct(productId);

            if(this.timer) {
                clearTimeout(this.timer)
            }

            this.timer = setTimeout(() => {
                this.timer = false
                this.next()
            }, 5000)
        },
    },
}

</script>

<style lang="scss">

.c-home {
    // position: fixed;
    // top: 0;
    // bottom: 0;
    // right: 0;
    // left: 0;
    width: 100%;
    height: vh(100);
}

.c-home_bg {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    // padding-top: calc(#{rem(50px)} + 31vw/4);

    &:after {
        content: "";
        z-index: 1;
        position: absolute;
        top: 50%;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        background-image: linear-gradient(180deg, rgba($color-darkest, 0), $color-darkest);
    }

    video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: contain;
        opacity: 0;
        transition: opacity $speed-slow $easing;

        html.is-ready & {
            opacity: 1;
            transition: opacity $speed $easing;
        }
    }
}

.c-home_hover {
    z-index: 10;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;

    a {
        flex: 1 1 0;
        user-select: none;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-image: radial-gradient(closest-side, rgba($color-lightest, .7) 0, rgba($color-lightest, 0) 100%);
            opacity: 0;
            transition: opacity $speed $easing;
        }

        &:focus-visible {

            &:after {
                opacity: 1;
            }
        }
    }
}

.c-home_inner {
    z-index: 1;
    position: fixed;
    bottom: 0;
    left: 0;
    display: grid;
    grid-column-gap: var(--grid-gutter);
    width: 100%;
    padding: var(--grid-gutter);

    html.-layout-mobile & {
        --font-size-h2: clamp(80px, calc(200/1400 * 100vw), 200px);
        height: calc(100% - var(--header-height));
        grid-template-areas:
            "list   list"
            "index  index"
            "title  ."
            "nav    nav";
        grid-template-rows: 1fr auto auto;
    }

    html.-layout-desktop & {
        grid-template-areas: "title list";
        grid-template-columns: grid-space(4/16) grid-space(12/16);
    }
}

.c-home_title {
    grid-area: title;
    word-spacing: 100vw;

    > div {
        width: 100%;
    }

    html.-layout-mobile & {
        transform: translate(0, -50%);
    }
}

.c-home_list {
    grid-area: list;

    html.-layout-mobile & {
        order: -1;
        width: 100%;
    }
}

.c-home_item {
    position: absolute;
    left: 0;
    width: 100%;

    html.-layout-mobile & {
        top: 0;
    }

    html.-layout-desktop & {
        bottom: 0;
        display: grid;
        grid-column-gap: var(--grid-gutter);
        grid-template-columns: grid-space(8/16) grid-space(4/16);
    }
}

.c-home_name {
    display: flex;
    flex-direction: column;
    max-width: 4.8em;

    html.-layout-desktop & {
        bottom: -.2em;
    }
}

.c-home_desc {
    --at-delay-in: .4s;

    margin-top: auto;

    html.-layout-mobile & {
        display: none;
    }
}

.c-home_link {
    opacity: 0;
    transition: opacity $speed $easing;
}

.c-home_counter {
    grid-area: index;
    display: flex;
    flex-direction: column;

    .c-heading {
        line-height: .8 !important;
    }
}

.c-home_indexes {
}

.c-home_index {

    &:not(:first-child) {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.c-home_total {
    margin-left: auto;
}

.c-home_proxy {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.c-home_nav {
    display: flex;
    align-items: center;
    grid-area: nav;
    opacity: 0;
    transform: translate(0, 100%);

    html.is-ready & {
        opacity: 1;
        transform: translate(0);
        transition: opacity $speed-slow $easing $speed, transform $speed-slow $easing $speed;
    }
}

.c-home_button {

    &.-product {
        margin-right: auto;
    }

    &.-prev,
    &.-next {
        //top: $accessible-size/4;
        display: flex;
        align-items: center;
        justify-content: center;
        width: $accessible-size;
        height: $accessible-size;

        &:before {
            content: "";
            position: absolute;
            top: calc(50% - 75%/2);
            left: calc(50% - 90%/2);
            width: 90%;
            height: 75%;
            border-radius: $radius-tiny;
            border: 1px solid currentColor;
        }

        &:after {
            font-size: rem(18px);
        }
    }

    &.-prev {

        &:after {
            content: "\2190";
        }
    }

    &.-next {

        &:after {
            content: "\2192";
        }
    }

    @media (max-width: $to-tiny) {

        &.-product {
            width: grid-space(1/2);
        }
    }

    @media (min-width: $from-tiny) {

        &.-product {
            width: grid-space(3/10);
        }
    }

    @media (min-width: $from-small) {

        &.-product {
            width: grid-space(4/16);
        }
    }
}

</style>
